<template>
  <div class="marketplace-page">
    <div class="marketplace container-fluid">
      <div class="container">
        <div class="marketplace-box">
          <div class="title">
            <p>
              {{ $t('marketplace_page.title') }} <br />
              <span class="yellow">{{ $t('marketplace_page.lern') }}</span>
              {{ $t('marketplace_page.lern') }}
            </p>
          </div>
          <div class="description">
            <span>{{ $t('marketplace_page.professional') }}</span>
            <span>{{ $t('marketplace_page.why') }}</span>
          </div>
          <div class="buttons">
            <button class="apply">{{ $t('marketplace_page.apply') }}</button>
            <button class="affiliates">
              {{ $t('marketplace_page.affiliates') }}
            </button>
          </div>
          <div class="scroll-down">
            <span>{{ $t('marketplace_page.scroll') }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="monopolize container-fluid">
      <div class="container">
        <div class="monopolize__title-box">
          <div class="low-title">
            <span>{{ $t('marketplace_page.lern') }}</span>
          </div>
          <div class="title">
            <span>{{ $t('marketplace_page.monopolize') }}</span>
          </div>
          <div class="description">
            <span>{{ $t('marketplace_page.why_not') }}</span>
          </div>
        </div>
        <div class="row">
          <div class="column-list">
            <div class="col-md-4 col-sm-4 left-column">
              <div class="item">
                <div class="title">
                  <span>{{ $t('marketplace_page.payout') }}</span>
                </div>
                <div class="description">
                  <span>{{ $t('marketplace_page.simply') }}</span>
                </div>
              </div>
              <div class="item">
                <div class="title">
                  <span>{{ $t('marketplace_page.teach') }}</span>
                </div>
                <div class="description">
                  <span>{{ $t('marketplace_page.draw') }}</span>
                </div>
              </div>
            </div>
            <div class="col-md-4 col-sm-4 center-column">
              <img src="./img/centerColumnImage.svg" alt="image" />
            </div>
            <div class="col-md-4 col-sm-4 right-column">
              <div class="item">
                <div class="title">
                  <span>{{ $t('marketplace_page.payou') }}</span>
                </div>
                <div class="description">
                  <span>{{ $t('marketplace_page.simply') }}</span>
                </div>
              </div>
              <div class="item">
                <div class="title">
                  <span>{{ $t('marketplace_page.teach') }}</span>
                </div>
                <div class="description">
                  <span>{{ $t('marketplace_page.draw') }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="meet container-fluid">
      <div class="container">
        <div class="meet__title-box">
          <div class="title">
            <span>{{ $t('marketplace_page.meet') }}</span>
          </div>
          <div class="description">
            <span>{{ $t('marketplace_page.meet_description') }}</span>
          </div>
        </div>
        <div class="row">
          <div class="trade-list">
            <div class="col-md-4 col-sm-4 item">
              <div class="logo">
                <img src="./img/logo-item.svg" alt="logo" />
              </div>
              <div class="title">
                <span>{{ $t('marketplace_page.tapoleon') }}</span>
                <span class="low-title">{{
                  $t('marketplace_page.stragist')
                }}</span>
              </div>
              <div class="description">
                <span>{{ $t('marketplace_page.far') }}</span>
              </div>
            </div>
            <div class="col-md-4 col-sm-4 item">
              <div class="logo">
                <img src="./img/logo-item2.svg" alt="logo" />
              </div>
              <div class="title">
                <span>{{ $t('marketplace_page.tapoleon') }}</span>
                <span class="low-title">{{
                  $t('marketplace_page.stragist')
                }}</span>
              </div>
              <div class="description">
                <span>{{ $t('marketplace_page.far') }}</span>
              </div>
            </div>
            <div class="col-md-4 col-sm-4 item">
              <div class="logo">
                <img src="./img/logo-item3.svg" alt="logo" />
              </div>
              <div class="title">
                <span>{{ $t('marketplace_page.tapoleon') }}</span>
                <span class="low-title">{{
                  $t('marketplace_page.stragist')
                }}</span>
              </div>
              <div class="description">
                <span>{{ $t('marketplace_page.far') }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="trader container-fluid">
      <img
        src="~@assets/img/marketplace-ball.svg"
        class="marketplace-ball"
        alt=""
      />
      <div class="container">
        <div class="trader__title-box">
          <div class="title">
            <span>{{ $t('marketplace_page.becom') }}</span>
          </div>
          <div class="description">
            <span>{{ $t('marketplace_page.support') }}</span>
          </div>
        </div>
        <div class="row">
          <div class="trader-list">
            <div class="col-md-4 col-sm-4 item">
              <div class="item-image">
                <img src="./img/trade-apply.svg" alt="apply" />
              </div>
              <div class="item-title">
                <span>{{ $t('marketplace_page.aply') }}</span>
              </div>
              <div class="item-description">
                <span>{{ $t('marketplace_page.aspiring') }}</span>
              </div>
            </div>
            <div class="col-md-4 col-sm-4 item">
              <div class="item-image">
                <img src="./img/trade-design.svg" alt="design" />
              </div>
              <div class="item-title">
                <span>{{ $t('marketplace_page.design') }}</span>
              </div>
              <div class="item-description">
                <span>{{ $t('marketplace_page.cerful') }}</span>
              </div>
            </div>
            <div class="col-md-4 col-sm-4 item">
              <div class="item-image">
                <img src="./img/trade-earn.svg" alt="earn" />
              </div>
              <div class="item-title">
                <span>{{ $t('marketplace_page.ern') }}</span>
              </div>
              <div class="item-description">
                <span>{{ $t('marketplace_page.indicators') }}</span>
              </div>
            </div>
          </div>

          <div class="trader__buttons">
            <button class="signaler">
              {{ $t('marketplace_page.signaler') }}
            </button>
            <button class="apply">
              {{ $t('marketplace_page.apply_for') }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="cards container-fluid">
      <div class="container">
        <div class="row">
          <div class="col-md-4 col-sm-4 cards_accept">
            <div class="cards__title">
              <span>{{ $t('marketplace_page.accept') }}</span>
            </div>
          </div>
          <div class="col-md-8 col-sm-8 cards__list">
            <div class="skrill">
              <img src="~@assets/img/skrill.png" alt="skrill" />
            </div>
            <div class="visa">
              <img src="~@assets/img/visa.png" alt="visa" />
            </div>
            <div class="mc">
              <img src="~@assets/img/mc.png" alt="master_card" />
            </div>
            <div class="neteller">
              <img src="~@assets/img/neteller.png" alt="neteller" />
            </div>
            <div class="wire">
              <img src="~@assets/img/wire.png" alt="wire" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss" src="./styles.scss"></style>
